var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{on:{"change":function($event){return _vm.triggerValidation()}}},[_c('b-row',[_c('b-form-checkbox',{attrs:{"name":"isQuarantineEnabled","disabled":_vm.readOnly},on:{"change":function($event){return _vm.handleQuarantineEnabled($event)}},model:{value:(_vm.config.enabled),callback:function ($$v) {_vm.$set(_vm.config, "enabled", $$v)},expression:"config.enabled"}},[_vm._v("\n      Quarantine Enabled\n    ")])],1),(_vm.config.enabled)?_c('div',{staticClass:"mt-4"},[_vm._l((_vm.$v.config.mapping.$each.$iter),function(m,i){return _c('b-row',{key:i,attrs:{"cols":"12"}},[_c('b-col',{staticClass:"align-content",attrs:{"cols":"11"}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Field","invalid-feedback":_vm.mappingError(m.field)}},[_c('b-form-select',{attrs:{"disabled":_vm.readOnly,"id":"input-quarantine-field","state":!m.field.$invalid,"options":_vm.clientMappedFields.map(m => {
                  return {
                    value: m.iftsm_uuid,
                    text: _vm.fieldNameToDisplay(m.field_name),
                    disabled: !m.column_number,
                  };
                }),"required":""},model:{value:(m.field.$model),callback:function ($$v) {_vm.$set(m.field, "$model", $$v)},expression:"m.field.$model"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Verification","invalid-feedback":_vm.mappingError(m.verification)}},[_c('b-form-select',{attrs:{"disabled":_vm.readOnly,"id":"input-quarantine-verification","state":!m.verification.$invalid,"options":_vm.verificationOptions,"required":""},model:{value:(m.verification.$model),callback:function ($$v) {_vm.$set(m.verification, "$model", $$v)},expression:"m.verification.$model"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Operator","invalid-feedback":_vm.mappingError(m.operator)}},[_c('b-form-select',{attrs:{"disabled":_vm.readOnly,"id":"input-quarantine-operator","state":!m.operator.$invalid,"options":_vm.operatorOptions,"required":""},model:{value:(m.operator.$model),callback:function ($$v) {_vm.$set(m.operator, "$model", $$v)},expression:"m.operator.$model"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Value","invalid-feedback":_vm.mappingError(m.value)}},[_c('b-form-input',{attrs:{"disabled":_vm.readOnly,"id":"input-quarantine-value","state":!m.value.$invalid,"min":"0","max":"100","type":"number"},model:{value:(m.value.$model),callback:function ($$v) {_vm.$set(m.value, "$model", $$v)},expression:"m.value.$model"}})],1)],1)],1)],1),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"1"}},[_c('b-button',{staticClass:"trash-button",attrs:{"variant":"danger","size":"sm","disabled":_vm.readOnly},on:{"click":function($event){return _vm.removeMapping(i)}}},[_c('feather',{attrs:{"type":"trash"}})],1)],1)],1)}),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"disabled":_vm.readOnly},on:{"click":_vm.addQuarantineConfig}},[_vm._v("\n          Add Mapping\n        ")])],1)],1)],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }