<template>
  <div>
    <b-row>
      <b-col cols='12'>
        <hr />
      </b-col>
    </b-row>

    <b-row class='mb-4'>
      <b-col cols='12' class='mb-2'>
        <h6>{{ title }} mapping</h6>
        <small>Normalize {{ title.toLowerCase() }} field based on key/value</small>
      </b-col>
    </b-row>

    <b-row class="mb-2" v-for="(entry, idx) in $v.configuration.$each.$iter" :key="idx">
      <b-col cols="4">
        <b-form-group label="Field" description="Field to apply" :state="!entry.field_name.$invalid"
                      :invalid-feedback="mappingError(entry.field_name)">
          <b-form-select
            @change="triggerValidation"
            :disabled="readOnly"
            v-model="entry.field_name.$model"
            :options="availableFieldsToNormalize"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Key" description="Key value from file" :state="!entry.key.$invalid"
                      :invalid-feedback="mappingError(entry.key)">
          <b-form-select :disabled="readOnly" v-model="entry.key.$model" :options="options" @change="triggerValidation">
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Value" description="Translate key to value"
                      :state="!entry.value.$invalid"
                      :invalid-feedback="mappingError(entry.value)">
          <b-form-input :disabled="readOnly" type="text" v-model.trim="entry.value.$model" @change="triggerValidation">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="1" class="d-flex justify-content-center align-items-center">
        <b-form-group>
          <b-button :disabled="readOnly" variant="light" @click="deleteEntry(idx)">
            <feather type="trash"></feather>
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12">
        <b-button :disabled="readOnly" variant="info" @click="addEntry()">
          Add {{ title }} mapping
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import errorHandler from '@/mixins/errorHandler';
import { required } from 'vuelidate/lib/validators';
import { isNullOrEmpty } from '@/helpers';

export default {
  mixins: [ errorHandler ],
  props: {
    configuration: {
      type: Array,
      default: () => [],
      required: true,
    },
    mapping: {
      type: Array,
      default: () => [],
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      availableFieldsToNormalize: [],
    };
  },
  beforeMount() {
    this.mapping.forEach(m => {
      this.availableFieldsToNormalize.push({
        value: m.field_name,
        text: m.field_name,
      });
    });

    this.triggerValidation();
  },
  validations() {
    return {
      configuration: {
        $each: {
          field_name: {
            required,
            isMapped(value) {
              const mapping = this.mapping.find(c => c.field_name === value);
              if (isNullOrEmpty(mapping.column_number)) {
                return false;
              }
              return true;
            },
          },
          key: {
            required,
            isUnique(value, c) {
              const mappings = this.configuration.filter(o => o.field_name === c.field_name && o.value === c.value);
              if (mappings.filter(m => m.key === value).length > 1) {
                return false;
              }
              return true;
            },
          },
          value: {
            required,
            isUnique(value, c) {
              if (value === '') {
                return true;
              }
              const mappings = this.configuration.filter(o => o.field_name === c.field_name && o.key === c.key);
              if (mappings.filter(m => m.value === value).length > 1) {
                return false;
              }
              return true;
            },
          },
        },
      },
    };
  },
  methods: {
    addEntry() {
      this.configuration.push({
        field_name: '',
        key: '',
        value: '',
      });
      this.triggerValidation();
    },
    deleteEntry(idx) {
      this.configuration.splice(idx, 1);
      this.triggerValidation();
    },
    triggerValidation() {
      this.$emit('form-validation', !this.$v.$invalid);
    },
    mappingError(field) {
      if (field.isUnique === false) {
        return 'The combination of the each configuration row must be unique';
      }
      if (!field.isRequired) {
        return 'Requires Value';
      }
      if (!field.numeric) {
        return 'Requires numeric value';
      }
      if (!field.minValue) {
        return 'Min value is 1';
      }
      if (!field.maxLength) {
        return 'Only allowed set up to 3 numbers';
      }
      return '';
    },
  },
};

</script>

<style scoped lang="scss">

</style>
