<template>
  <div>
    <b-row cols="12" class="mt-4">
      <b-col cols="4" class="mt-2">
        <label>Provider Filepath</label>
      </b-col>
      <b-col cols="8">
        <VueSelect
          v-model="configuration.provider_id"
          :disabled="readOnly"
          item-value="id"
          :reduce="provider => provider.id"
          v-if="filepaths.length > 0"
          :options="filepaths"/>
        <label v-else style="color: red">No filepath available for this file type</label>
        <small v-if="filepaths.length > 0 && !isValid('configuration.provider_id')" style="color: red">
          Specify the filepath
        </small>
      </b-col>
    </b-row>
    <b-row cols="12" class="mt-4">
      <b-col cols="4" class="mt-2">
        <label>File separator</label>
      </b-col>
      <b-col
        cols="8"
        :state="isValid('configuration.delimiter')"
        :invalid-feedback="handleError(`configuration.delimiter`)">
        <b-form-select
          :disabled="readOnly" id="file-separator"
          :state="isValid('configuration.delimiter')"
          v-model="configuration.delimiter"
          @change="forceValidation"
          :options="fileSeparatorOptions"/>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols='12'>
        <hr/>
      </b-col>
    </b-row>

    <FileMappingConfig
      :mapping="configuration.mapping"
      :readOnly="readOnly"
      :uniqueIdConfigs="uniqueIdConfigs"
      @form-validation="($ev) => triggerValidation('fileMapping', $ev)"/>

    <hr/>

    <b-row class="mt-4">
      <b-col cols="12" class="mb-2">
        <h5>Override Fingerprint Value</h5>
        <small>Specify the column number if you want to override the generated fingerprint with a column in the file</small>
        <b-col
          cols="2"
          class="ml-0 pl-0"
          :state="isValid('configuration.external_unique_id_column_number')"
          :invalid-feedback="handleError(`configuration.external_unique_id_column_number`)">
          <b-form-input
            id="input-group-fingerprint-override"
            @change="forceValidation"
            :state="isValid('configuration.external_unique_id_column_number')"
            :disabled="readOnly"
            v-model="configuration.external_unique_id_column_number"
            min="0"
            type="number"/>
        </b-col>
      </b-col>
    </b-row>

    <hr />
    <b-row>
      <b-col cols='12' class='mb-3'>
        <h5>Quarantine</h5>
        <small>Quarantine configuration and report</small>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols='12' class='mb-2'>
        <h6>Configuration</h6>
        <b-card class='mt-2'>
          <QuarantineConfig
            :config='configuration.quarantine_config'
            :readOnly="readOnly"
            :clientMappedFields='configuration.mapping'
            @form-validation="($ev) => triggerValidation('quarantineConfig', $ev)">
          </QuarantineConfig>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols='12' class='mb-2'>
        <h6  class='mt-4 mb-2'>Report</h6>
          <QuarantineReport :fileTypeId='Number(fileTypeId)' :providerId='Number(providerId)' :onboardingClientId='Number(onboardingClientId)'>
          </QuarantineReport>
      </b-col>
    </b-row>


    <!-- Normalizers -->
    <NormalizerConfiguration
      title="Relationship"
      :configuration="configuration.normalizers.relationship"
      :readOnly="readOnly"
      :mapping="configuration.mapping"
      :options="eligibilityRelationshipOptions"
      @form-validation="($ev) => triggerValidation('relationshipNormalizer', $ev)"/>

    <NormalizerConfiguration
      title="Gender"
      :configuration="configuration.normalizers.gender"
      :readOnly="readOnly"
      :mapping="configuration.mapping"
      :options="eligibilityGenderOptions"
      @form-validation="($ev) => triggerValidation('genderNormalizer', $ev)"/>

    <DateNormalizer
      :configuration="configuration.normalizers.date"
      :readOnly="readOnly"
      :mapping="configuration.mapping"
      :options="eligibilityGenderOptions"
      @form-validation="($ev) => triggerValidation('dateNormalizer', $ev)"/>

      <GenericEligibilityMapping
      :configuration="configuration.genericEligibilityMapping"
      :readOnly="readOnly"
      :mapping="configuration.mapping"
      :eligibilityProviders="eligibilityProviders"
      @form-validation="($ev) => triggerValidation('genericEligibility', $ev)"/>
  </div>
</template>

<script>

import { VueSelect } from 'vue-select';
import { eligibilityRelationshipOptions, eligibilityGenderOptions } from '@/scripts/definitions';
import errorHandler from '@/mixins/errorHandler';
import NormalizerConfiguration from '@/views/Onboarding/GenericImporter/NormalizerConfiguration.vue';
import DateNormalizer from '@/views/Onboarding/GenericImporter/DateNormalizer.vue';
import { numeric, required } from 'vuelidate/lib/validators';
import FileMappingConfig from '@/views/Onboarding/GenericImporter/FileMappingConfig.vue';
import GenericEligibilityMapping from '@/views/Onboarding/GenericImporter/GenericEligibilityMapping.vue';
import QuarantineConfig from '@/views/Onboarding/Quarantine/GenericImporter/Config.vue';
import QuarantineReport from '@/views/Onboarding/Quarantine/GenericImporter/Report.vue';


const fileSeparatorOptions = [
  { value: ','.charCodeAt(0), text: 'Comma (,)' },
  { value: ';'.charCodeAt(0), text: 'Semicolon (;)' },
  { value: '|'.charCodeAt(0), text: 'Pipe (|)' },
  { value: '~'.charCodeAt(0), text: 'Tilde (~)' },
  { value: '\t'.charCodeAt(0), text: 'Tab' },
];

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    QuarantineReport,
    QuarantineConfig,
    FileMappingConfig,
    DateNormalizer,
    NormalizerConfiguration,
    VueSelect,
    GenericEligibilityMapping,
  },
  mixins: [ errorHandler ],
  data() {
    return {
      eligibilityRelationshipOptions,
      eligibilityGenderOptions,
      availableFieldsToNormalize: [],
      fileSeparatorOptions,
      isFormValid: false,
      formValidations: {
        self: false,
        fileMapping: false,
        relationshipNormalizer: false,
        genderNormalizer: false,
        dateNormalizer: false,
      },
      fileTypeId: null,
      providerId: null,
      onboardingClientId: null,
    };
  },
  props: {
    configuration: {
      type: Object,
      default: () => {},
    },
    filepaths: {
      type: Array,
      default: () => [],
    },
    uniqueIdConfigs: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    eligibilityProviders: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    'configuration.provider_id': {
      handler() {
        this.configuration.folder = this.filepaths.find(filepath => filepath.id === this.configuration.provider_id)?.label;
        return this.forceValidation();
      },
      immediate: true,
    },
  },
  validations() {
    return {
      configuration: {
        provider_id: { required, numeric },
        external_unique_id_column_number: { numeric },
        delimiter: { required },
      },
    };
  },
  beforeMount() {
    this.formValidations.self = !this.$v.$invalid;
    this.fileTypeId = this.$route.params.fileTypeId;
    this.providerId = this.$route.params.providerId;
    this.onboardingClientId = this.$route.params.clientId;
  },
  methods: {
    triggerValidation(name, isValid) {
      switch (name) {
        case 'quarantineConfig':
          this.formValidations.quarantineConfig = isValid;
          break;
        case 'fileMapping':
          this.formValidations.fileMapping = isValid;
          break;
        case 'relationshipNormalizer':
          this.formValidations.relationshipNormalizer = isValid;
          break;
        case 'genderNormalizer':
          this.formValidations.genderNormalizer = isValid;
          break;
        case 'dateNormalizer':
          this.formValidations.dateNormalizer = isValid;
          break;
        case 'genericEligibility':
          this.formValidations.genericEligibility = isValid;
          break;
        case 'self':
          this.formValidations.self = !this.$v.$invalid;
          break;
        default:
          console.error('Unknown validation name: ', name);
          break;
      }

      this.$emit('form-validation', !Object.values(this.formValidations).includes(false));
    },
    forceValidation() {
      this.triggerValidation('self');
    },
    mappingError(field) {
      if (!field.isRequired) {
        return 'Requires Value';
      }
      if (!field.numeric) {
        return 'Requires numeric value';
      }
      if (!field.minValue) {
        return 'Min value is 1';
      }
      if (!field.maxLength) {
        return 'Only allowed set up to 3 numbers';
      }
      return '';
    },
    addDateEntry() {
      this.configuration.dateMapping.push({ field_name: null, key: null, value: null });
      this.triggerValidation();
    },
    deleteDateEntry() {
      this.configuration.dateMapping.push({ field_name: null, key: null, value: null });
      this.triggerValidation();
    },
  },
};
</script>

<style scoped lang="scss">
  @import "~vue-select/dist/vue-select.css";
</style>

<!--
  Fix vue-select actions to be clickable
-->
<style>
  .vs__actions {
    cursor: pointer;
  }
</style>
