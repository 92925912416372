<template>
  <div @change='triggerValidation()'>
    <b-row>
      <b-form-checkbox
        name='isQuarantineEnabled'
        :disabled='readOnly'
        v-model='config.enabled'
        @change='handleQuarantineEnabled($event)'>
        Quarantine Enabled
      </b-form-checkbox>
    </b-row>
    <div v-if='config.enabled' class='mt-4'>
      <b-row cols='12' v-for='(m, i) in $v.config.mapping.$each.$iter' :key='i'>
        <b-col cols='11' class='align-content'>
          <b-row>
            <b-col>
              <b-form-group
                label='Field'
                :invalid-feedback='mappingError(m.field)'>
                <b-form-select
                  :disabled='readOnly'
                  id='input-quarantine-field'
                  v-model='m.field.$model'
                  :state='!m.field.$invalid'
                  :options='clientMappedFields.map(m => {
                    return {
                      value: m.iftsm_uuid,
                      text: fieldNameToDisplay(m.field_name),
                      disabled: !m.column_number,
                    };
                  })' required />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label='Verification'
                :invalid-feedback='mappingError(m.verification)'>
                <b-form-select
                  :disabled='readOnly'
                  id='input-quarantine-verification'
                  :state='!m.verification.$invalid'
                  v-model='m.verification.$model'
                  :options='verificationOptions' required />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label='Operator'
                :invalid-feedback='mappingError(m.operator)'>
                <b-form-select
                  :disabled='readOnly'
                  id='input-quarantine-operator'
                  :state='!m.operator.$invalid'
                  v-model='m.operator.$model'
                  :options='operatorOptions' required />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label='Value'
                :invalid-feedback='mappingError(m.value)'>
                <b-form-input
                  :disabled='readOnly'
                  id='input-quarantine-value'
                  :state='!m.value.$invalid'
                  v-model='m.value.$model'
                  min='0'
                  max='100'
                  type='number' />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols='1' class='d-flex justify-content-end'>
          <b-button
            class='trash-button'
            variant='danger'
            size='sm'
            @click='removeMapping(i)'
            :disabled='readOnly'>
            <feather type='trash'></feather>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col class='d-flex justify-content-end'>
          <b-button @click='addQuarantineConfig' :disabled='readOnly'>
            Add Mapping
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  maxValue, minValue, numeric, required,
} from 'vuelidate/lib/validators';
import helpers from '@/helpers';

const { fieldNameToDisplay } = helpers;

export default {
  name: 'QuarantineConfig',
  props: {
    config: {
      type: Object,
      default: () => ({
        enabled: false,
        mapping: [],
      }),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    clientMappedFields: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    active: false,
    operatorOptions: [
      { value: '>', text: 'Greater than' },
      { value: '<', text: 'Less than' },
      { value: '=', text: 'Equal to' },
      { value: '!=', text: 'Not equal to' },
      { value: '>=', text: 'Greater or Equal to' },
      { value: '<=', text: 'Less or Equal to' },
    ],
    verificationOptions: [
      { value: 'email_api', text: 'Email Api' },
    ],
    fieldNameToDisplay,
  }),
  validations() {
    return {
      config: {
        mapping: {
          $each: {
            field: { required },
            verification: { required },
            operator: { required },
            value: {
              required,
              numeric,
              maxValue: maxValue(100),
              minValue: minValue(1),
            },
          },
        },
      },
    };
  },
  beforeMount() {
    this.triggerValidation();
  },
  methods: {
    removeMapping(index) {
      this.config.mapping.splice(index, 1);
    },
    handleQuarantineEnabled(value) {
      if (value && this.config.mapping.length === 0) {
        this.addQuarantineConfig();
      }
    },
    addQuarantineConfig() {
      this.config.mapping.push({
        field: '',
        verification: '',
        operator: '',
        value: '',
      });
      this.triggerValidation();
    },
    mappingError(field) {
      if (!field.required) {
        return 'Requires Value';
      }
      if (!field.numeric) {
        return 'Requires numeric value';
      }
      if (!field.minValue) {
        return 'Min value is 1';
      }
      if (!field.maxValue) {
        return 'Max value is 100';
      }
      return '';
    },
    triggerValidation() {
      this.$emit('form-validation', !this.$v.$invalid);
    },
  },
};
</script>
<style scoped>
.trash-button {
  position: absolute;
  top: 55%;
  transform: translateY(-55%);
}
</style>
