<template>
  <div>
    <b-row>
      <b-col cols='12'>
        <hr />
      </b-col>
    </b-row>
    <b-row class='mb-4'>
      <b-col cols='12' class='mb-2'>
        <h6>Generic importer to eligibility mapping</h6>
        <small>Map field from generic importer to eligibility file</small>
      </b-col>
    </b-row>
    <div class="mb-2" v-for="(config, idx) in $v.configuration.$each.$iter" :key="idx" @change="triggerValidation()">
      <b-row class='mt-4'>
        <b-col>
          <b-card class='mb-4'>
            <template #header>
              <h6 class='mb-0'>Provider {{ parseInt(idx) + 1 }}</h6>
              <b-button @click='deleteProviderEntry(idx)' title='Remove provider' v-b-tooltip.hover class='float-right'
                variant='link' style='min-width: unset; padding: 0' :id="'btn-remove-provider-'">
                <feather type='trash'></feather>
              </b-button>
            </template>
            <b-row cols="12">
              <b-col cols="5">
                <b-form-group label="Eligibility provider" description="Eligibility Provider to apply"
                  :state="!config.eligibility_provider_id.$invalid"
                  :invalid-feedback="mappingError('provider', config.eligibility_provider_id)">
                  <b-form-select @change="triggerValidation()" :disabled="readOnly"
                    v-model="config.eligibility_provider_id.$model" :options="providers">
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="mb-2" v-for="(mapping, idxMapping) in config.mapping.$each.$iter" :key="idxMapping"
              @change="triggerValidation()">
              <b-row cols="12">
                <b-col cols="5">
                  <b-form-group label="Generic field" description="Generic field to apply"
                    :state="!mapping.generic_field.$invalid"
                    :invalid-feedback="mappingError('Generic', mapping.generic_field)">
                    <b-form-select @change="triggerValidation()" :disabled="readOnly"
                      v-model="mapping.generic_field.$model" :options="availableGenericFieldsToMap">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="5">
                  <b-form-group label="Eligibility field" description="Eligibility Field to apply"
                    :state="!mapping.eligibility_field.$invalid"
                    :invalid-feedback="mappingError('Eligibility', mapping.eligibility_field)">
                    <b-form-select @change="triggerValidation()" :disabled="readOnly"
                      v-model="mapping.eligibility_field.$model" :options="availableEligibilityFieldsToMap">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="1" class="d-flex justify-content-center align-items-center">
                  <b-button :disabled="readOnly" variant="light" @click="deleteMappingEntry(idx, idxMapping)">
                    <feather type="trash"></feather>
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <b-row class="mb-2">
              <b-col cols="12">
                <b-button :disabled="readOnly" variant="info" @click="addMappingEntry(idx)">
                  Add mapping
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

    </div>
    <b-row class="mb-2">
      <b-col cols="12">
        <b-button :disabled="readOnly" variant="info" @click="addProvider()">
          Add provider
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { isNullOrEmpty } from '@/helpers';
import { required } from 'vuelidate/lib/validators';
import { EligibilityFields } from '@/scripts/definitions';
import axios from 'axios';

export default {
  props: {
    configuration: {
      type: Array,
      required: true,
    },
    mapping: {
      type: Array,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    eligibilityProviders: {
      type: Array,
      default: () => { },
    },
  },
  data() {
    return {
      availableGenericFieldsToMap: [],
      availableEligibilityFieldsToMap: [],
      eligibilityProviderId: null,
      providers: [],
    };
  },
  async beforeMount() {
    this.mapping.forEach(m => {
      this.availableGenericFieldsToMap.push({
        value: m.field_name,
        text: m.field_name,
      });
    });

    this.availableEligibilityFieldsToMap = EligibilityFields.map(f => ({
      value: f.key,
      text: f.label,
    }));

    const { data } = await axios.get(`v1/clients/${this.$route.params.clientId}/eligibility/settings`);
    if (data?.multiProvider?.data) {
      data.multiProvider.data.forEach(provider => {
        const availableProvider = this.eligibilityProviders.find(p => provider.folder === p.filepath);
        if (availableProvider) {
          this.providers.push({
            value: availableProvider.id,
            text: provider.folder,
          });
        }
      });
    }

    this.triggerValidation();
  },
  validations() {
    return {
      configuration: {
        $each: {
          eligibility_provider_id: {
            isUnique(value) {
              if (value === '') {
                return true;
              }
              return this.configuration.filter(c => c.eligibility_provider_id === value).length <= 1;
            },
            required,
          },
          mapping: {
            $each: {
              generic_field: {
                required,
                isUnique(field) {
                  if (field === '') {
                    return true;
                  }
                  return this.configuration.filter(o => o.field_name === field).length <= 1;
                },
                isMapped(value) {
                  const mapping = this.mapping.find(c => c.field_name === value);
                  if (isNullOrEmpty(mapping.column_number)) {
                    return false;
                  }
                  return true;
                },
              },
              eligibility_field: {
                required,
              },
            },
          },
        },
      },
    };
  },
  methods: {
    triggerValidation() {
      this.$emit('form-validation', !this.$v.$invalid);
    },
    addProvider() {
      this.$v.configuration.$touch();
      this.configuration.push({
        eligibility_provider_id: '',
        mapping: [
          {
            generic_field: '',
            eligibility_field: '',
          },
        ],
      });
      this.triggerValidation();
    },
    addMappingEntry(idx) {
      this.$v.configuration.$touch();
      this.configuration[idx].mapping.push({
        generic_field: '',
        eligibility_field: '',
      });
      this.triggerValidation();
    },
    deleteMappingEntry(idx, idxMapping) {
      this.configuration[idx].mapping.splice(idxMapping, 1);
      this.triggerValidation();
    },
    deleteProviderEntry(idx) {
      this.configuration.splice(idx, 1);
      this.triggerValidation();
    },
    mappingError(type, field) {
      if (type === 'provider') {
        if (field.$invalid && isNullOrEmpty(field.$model)) {
          return 'Required';
        }

        if (field.$error) {
          return 'Duplicated provider path';
        }
      }

      if (field.$invalid && isNullOrEmpty(field.$model)) {
        return 'Required';
      }

      if (field.$error) {
        return 'The selected field is not currently mapped in the generic import schema';
      }

      return '';
    },
  },
};

</script>

<style></style>
