<template>
  <div>
    <b-row>
      <b-col cols='12'>
        <hr/>
      </b-col>
    </b-row>

    <b-row class='mb-4'>
      <b-col cols='12' class='mb-2'>
        <h6>Date Format</h6>
        <small>Normalize date field to Sword format</small>
      </b-col>
    </b-row>

    <b-row class="mb-2" v-for="(date, idx) in $v.configuration.$each.$iter" :key="idx"
           @change="triggerValidation()">
      <b-col cols="5">
        <b-form-group label="Field" description="Field to apply" :state="!date.field_name.$invalid"
                      :invalid-feedback="mappingError('Date', date.field_name)">
          <b-form-select @change="triggerValidation()" :disabled="readOnly" v-model="date.field_name.$model" :options="availableFieldsToNormalize">
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Format" description="Date Format to Apply" :state="!date.format.$invalid"
                      :invalid-feedback="mappingError('Date', date.format)">
          <b-form-input @change="triggerValidation()" :disabled="readOnly" type="text" v-model.trim="date.format.$model">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="1" class="d-flex justify-content-center align-items-center">
        <b-button :disabled="readOnly" variant="light" @click="deleteDateEntry(idx)">
          <feather type="trash"></feather>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12">
        <b-button :disabled="readOnly" variant="info" @click="addDateEntry()">
          Add date field
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { isNullOrEmpty, validDateFormat } from '@/helpers';
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    configuration: {
      type: Array,
      required: true,
    },
    mapping: {
      type: Array,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      availableFieldsToNormalize: [],
    };
  },
  beforeMount() {
    this.mapping.forEach(m => {
      this.availableFieldsToNormalize.push({
        value: m.field_name,
        text: m.field_name,
      });
    });

    this.triggerValidation();
  },
  validations() {
    return {
      configuration: {
        $each: {
          field_name: {
            required,
            isUnique(field) {
              if (field === '') {
                return true;
              }
              return this.configuration.filter(o => o.field_name === field).length <= 1;
            },
            isMapped(value) {
              const mapping = this.mapping.find(c => c.field_name === value);
              if (isNullOrEmpty(mapping.column_number)) {
                return false;
              }
              return true;
            },
          },
          format: {
            required,
            validDateFormat,
          },
        },
      },
    };
  },
  methods: {
    triggerValidation() {
      this.$emit('form-validation', !this.$v.$invalid);
    },
    addDateEntry() {
      this.$v.configuration.$touch();
      if (!this.$v.configuration.$invalid) {
        this.configuration.push({
          field_name: '',
          format: '',
        });
      }
    },
    deleteDateEntry(idx) {
      this.configuration.splice(idx, 1);
    },
    mappingError(type, field) {
      if (field.$error) {
        return `${type} mapping is required`;
      }
      return '';
    },
  },
};
</script>
