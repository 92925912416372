<template>
  <div v-if='loading'>
    <b-row>
      <b-col cols='12'>
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <div v-if='quarantineIngestion'>
      <b-card>
        <p><b>Filename</b>: {{ quarantineIngestion.filename }}</p>
        <p><b>Overall Count</b>: {{ quarantineIngestion.number_of_lines }}</p>
        <p><b>Created</b>: {{ formatDate(quarantineIngestion.created_at) }} UTC</p>
        <p><b>Status</b>:
          <b-badge v-if="quarantineIngestion.status === 'pending'" variant="warning">Pending</b-badge>
          <b-badge v-if="quarantineIngestion.status === 'processing'" variant="warning">Processing</b-badge>
          <b-badge v-if="quarantineIngestion.status === 'analyzing'" variant="warning">Analyzing</b-badge>
          <b-badge v-if="quarantineIngestion.status === 'completed'" variant="success">Completed</b-badge>
          <b-badge v-if="quarantineIngestion.status === 'failed'" variant="danger">Failed</b-badge>
        </p>
        <div v-if='quarantineIngestion.error_message'>
          <p><b>Error Message</b>: {{ quarantineIngestion.error_message }}</p>
        </div>
        <b-row>
          <b-col>
            <b>Queue Details</b>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="ml-3 mt-1 mb-3">
            <div v-if="quarantineIngestion.queue_details">
              <p class="mb-0"><b>Status:</b>
                <b-badge class="ml-1" v-if="quarantineIngestion.queue_details.status === 'pending'" variant="warning">Pending</b-badge>
                <b-badge class="ml-1" v-if="quarantineIngestion.queue_details.status === 'accepted'" variant="success">Accepted</b-badge>
                <b-badge class="ml-1" v-if="quarantineIngestion.queue_details.status === 'failed'" variant="danger">Failed</b-badge>
                <b-badge class="ml-1" v-if="quarantineIngestion.queue_details.status === 'done'" variant="success">Finished</b-badge>
              </p>
              <p class="mb-0"><b>Last Action:</b>
                <b-badge class="ml-1" v-if="quarantineIngestion.queue_details.action === 'pending'" variant="warning">Pending</b-badge>
                <b-badge class="ml-1" v-if="quarantineIngestion.queue_details.action === 'accept'" variant="success">Accepted</b-badge>
                <b-badge class="ml-1" v-if="quarantineIngestion.queue_details.action === 'reject'" variant="danger">Rejected</b-badge>
              </p>
              <p class="mb-0"><b>Attempts:</b> {{quarantineIngestion.queue_details.attempts}}</p>
              <p class="mb-0" v-if="quarantineIngestion.queue_details.error_message"><b>Error Details:</b></p>
              <b-form-textarea
                v-if="quarantineIngestion.queue_details.error_message"
                v-model="quarantineIngestion.queue_details.error_message"
                disabled
                rows="2"
                max-rows="4"/>
            </div>
            <div v-else>
              <span>Nothing in Queue</span>
            </div>
          </b-col>
        </b-row>
        <p><b>Quarantined</b>:
          <b-badge v-if='quarantineIngestion.quarantined_file' variant='danger'>Yes</b-badge>
          <b-badge v-else variant='success'>No</b-badge>
        </p>
        <p><b>Config</b>:</p>
        <b-row v-for='(config, i) in quarantineIngestion.configs' :key='i'>
          <b-col cols='12'>
            <b-card>
              <b-row>
                <b-col cols='3'>
                  <b-form-group label='Field'>
                    <b-form-input :disabled='true' type='text' v-model='config.field' />
                  </b-form-group>
                </b-col>
                <b-col cols='3'>
                  <b-form-group label='Verification Type'>
                    <b-form-input :disabled='true' type='text' v-model='config.verification' />
                  </b-form-group>
                </b-col>
                <b-col cols='3'>
                  <b-form-group label='Operator'>
                    <b-form-input :disabled='true' type='text' v-model='config.operator' />
                  </b-form-group>
                </b-col>
                <b-col cols='3'>
                  <b-form-group label='Threshold Value'>
                    <b-form-input :disabled='true' type='text' v-model='config.value' />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <div class='mt-4'
             v-if='quarantineIngestion?.report?.checks?.failed && Object.keys(quarantineIngestion.report.checks.failed).length > 0'>
          <b-row>
            <b-col>
              <span><b>Failed Checks</b></span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <ChecksTable :configs='quarantineIngestion.configs' :items='quarantineIngestion.report.checks.failed'
                           :fields='fields'></ChecksTable>
            </b-col>
          </b-row>
        </div>
        <div class='mt-4'
             v-if='quarantineIngestion?.report?.checks?.success && Object.keys(quarantineIngestion.report.checks.success).length > 0'>
          <b-row>
            <b-col>
            <span>
              <b>Successful Checks</b>
                <b-button v-b-toggle.success-checks-table @click='quarantineSuccessChecksOpenHandler()'
                          class='ml-2 btn btn-info btn-sm'>
                  {{ quarantineSuccessChecksOpen ? 'Hide' : 'Show' }} Details
                </b-button>
            </span>
            </b-col>
          </b-row>
          <b-row class='mt-3'>
            <b-col>
              <b-collapse id="success-checks-table">
                <ChecksTable :configs='quarantineIngestion.configs' :items='quarantineIngestion.report.checks.success'
                             :fields='fields' :success='true'></ChecksTable>
              </b-collapse>
            </b-col>
          </b-row>
        </div>
        <div v-if='quarantineIngestion.quarantined_file && !hideActions && quarantineIngestion?.queue_details?.status !== "pending"'>
          <b-row class='mt-4'>
            <b-col>
              <!-- how to add a overlay to the button -->
              <b-button variant='success' v-b-tooltip.hover title='Promote this quarantined revision to production'
                        @click='openModalHandler(MODAL_ACTIONS.ACCEPT)' :disabled='loading'>
                Accept Ingestion
              </b-button>
              <b-button variant='danger' v-b-tooltip.hover title='Delete this revision from quarantine' class='ml-2'
                        @click='openModalHandler(MODAL_ACTIONS.REJECT)' :disabled='loading'>
                Reject Ingestion
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-modal ref='generic-quarantine-actions-modal' hide-footer title='Confirm Quarantine Status Change'>
        <div class='d-block text-center'>
          <p>{{ modal.message }}</p>
          <div class='mt-5'>
            <b-form-group
              label='Provide a reason for this action'
              label-for='quarantine-actions-modal-comment'
              label-align='left'
              class='mt-3'>
            </b-form-group>
            <b-form-textarea
              id='quarantine-actions-modal-comment'
              v-model='modal.comment'
              placeholder='Reason to proceed'
              rows='3'
              max-rows='6' />
          </div>
        </div>
        <b-button class='mt-3' variant='danger' block :disabled='loading' @click='acceptModalAction'>I'm Sure</b-button>
        <b-button class='mt-2' variant='info' block @click='hideModal'>Cancel</b-button>
      </b-modal>
    </div>
    <div v-else>
      <span>No previous report found for this importer</span>
    </div>
  </div>
</template>
<script>

import moment from 'moment/moment';
import ChecksTable from '@/views/Onboarding/Quarantine/Common/ChecksTable.vue';
import { isNullOrEmpty } from '@/helpers';

const MODAL_ACTIONS = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  RERUN: 'rerun',
};

export default {
  name: 'QuarantineReport',
  components: { ChecksTable },
  props: {
    fileTypeId: {
      type: Number,
      required: true,
    },
    providerId: {
      type: Number,
      required: true,
    },
    onboardingClientId: {
      type: Number,
      required: true,
    },
  },
  async beforeMount() {
    await this.loadingQuarantineIngestion();
  },
  data() {
    return {
      loading: true,
      MODAL_ACTIONS,
      hideActions: false,
      quarantineIngestion: null,
      modal: {
        message: 'Are you sure you want to promote this quarantined revision to production?',
        action: null,
        comment: null,
      },
      quarantineSuccessChecksOpen: false,
      fields: [
        {
          key: 'field',
          label: 'Field',
        },
        {
          key: 'value',
          label: 'Actual',
        },
        {
          key: 'operator',
          label: 'Operator',
        },
        {
          key: 'expected',
          label: 'Expected',
        },
        {
          key: 'absolute',
          label: 'Absolute',
        },
      ],
    };
  },
  methods: {
    isNullOrEmpty,
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
    showModal() {
      this.$refs['generic-quarantine-actions-modal'].show();
    },
    hideModal() {
      this.$refs['generic-quarantine-actions-modal'].hide();
    },
    openModalHandler(action) {
      switch (action) {
        case MODAL_ACTIONS.ACCEPT:
          this.modal.message = 'Are you sure you want to promote this quarantined revision to production?';
          break;
        case MODAL_ACTIONS.REJECT:
          this.modal.message = 'Are you sure you want to delete this revision from quarantine?';
          break;
        default:
          this.$noty.error(`Invalid action provided: ${action}`);
          return;
      }

      this.modal.comment = null;
      this.modal.action = action;
      this.showModal();
    },
    async loadingQuarantineIngestion() {
      this.loading = true;
      try {
        this.quarantineIngestion = await this.$store.dispatch('Sftp/GenericImporter/getQuarantineIngestion', {
          file_type_id: this.fileTypeId,
          provider_id: this.providerId,
          onboarding_client_id: this.onboardingClientId,
        }).then(response => response.data);
      } catch (err) {
        this.$noty.error(err?.response?.data?.message || 'Something went wrong');
      } finally {
        this.loading = false;
      }
    },
    acceptModalAction() {
      if (this.modal.action !== MODAL_ACTIONS.RERUN && (!this.modal.comment || this.modal.comment.length < 10)) {
        this.$noty.error('Please provide a comment to proceed. It should at least be 10 characters long');
        return;
      }
      switch (this.modal.action) {
        case MODAL_ACTIONS.ACCEPT:
          this.acceptQuarantine();
          break;
        case MODAL_ACTIONS.REJECT:
          this.rejectQuarantine();
          break;
        default:
          break;
      }
      this.hideModal();
    },
    async acceptQuarantine() {
      try {
        this.loading = true;
        await this.$store.dispatch('Sftp/GenericImporter/acceptQuarantinedIngestion', {
          file_type_id: this.$route.params.fileTypeId,
          provider_id: this.$route.params.providerId,
          onboarding_client_id: this.$route.params.clientId,
          payload: {
            ingestion_id: this.quarantineIngestion.uuid,
            comment: this.modal.comment,
          },
        });
        this.$noty.success('Quarantined ingestion scheduled to be promoted to production');
        this.hideActions = true;
        await this.loadingQuarantineIngestion();
      } catch (err) {
        this.$noty.error(err?.response?.data?.message || 'Something went wrong');
      } finally {
        this.loading = false;
      }
    },
    async rejectQuarantine() {
      try {
        this.loading = true;
        await this.$store.dispatch('Sftp/GenericImporter/rejectQuarantinedIngestion', {
          file_type_id: this.$route.params.fileTypeId,
          provider_id: this.$route.params.providerId,
          onboarding_client_id: this.$route.params.clientId,
          payload: {
            ingestion_id: this.quarantineIngestion.uuid,
            comment: this.modal.comment,
          },
        });
        this.$noty.success('Quarantined ingestion scheduled to be deleted');
        this.hideActions = true;
        await this.loadingQuarantineIngestion();
      } catch (err) {
        this.$noty.error(err?.response?.data?.message || 'Something went wrong');
      } finally {
        this.loading = false;
      }
    },
    quarantineSuccessChecksOpenHandler() {
      this.quarantineSuccessChecksOpen = !this.quarantineSuccessChecksOpen;
    },
  },
};
</script>
