<template>
  <div>
    <b-row class="mt-4">
      <b-col cols="12" class="mb-2">
        <h5>File Mapping</h5>
        <small>Specify the column number for each field</small>
      </b-col>
    </b-row>
    <div class="mt-4">
      <b-row cols="12">
        <b-col cols="2" v-for='(value, index) in $v.mapping.$each.$iter' :key='index'>
          <b-form-group
            :id="'input-group-' + value.$model.field_name"
            :label="fieldNameToDisplay(value.$model.field_name)"
            :label-for="'input-group-' + value.$model.field_name"
            :invalid-feedback="mappingError(value.column_number)">
            <b-form-input
              :id="'input-group-' + value.$model.field_name"
              :title="value.$model.field_name"
              :disabled="readOnly"
              @change="triggerValidation"
              :state="!value.column_number.$invalid"
              min="0"
              v-model="value.$model.column_number"
              type="number"/>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda';
import { numeric, minValue, maxLength } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';

import helpers from '@/helpers';

const { fieldNameToDisplay } = helpers;

const isNullOrEmpty = R.either(R.isNil, R.isEmpty);
export default {
  name: 'FileMappingConfig',
  components: {},
  mixins: [ errorHandler ],
  props: {
    mapping: {
      type: Array,
      default: () => {},
    },
    uniqueIdConfigs: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFormValid: true,
      mappingNew: {},
      fieldNameToDisplay,
    };
  },
  validations() {
    return {
      mapping: {
        $each: {
          column_number: {
            numeric,
            maxLength: maxLength(3),
            minValue: minValue(1),
            isRequired(value, field) {
              if ((field.is_required || this.uniqueIdConfigs.includes(field.field_name)) && isNullOrEmpty(value)) {
                return false;
              }

              return true;
            },
          },
        },
      },
    };
  },
  beforeMount() {
    // set empty where value is 0
    this.mapping.forEach((i, m) => {
      this.mapping[m].column_number = this.mapping[m].column_number === 0 ? null : this.mapping[m].column_number;
    });

    this.triggerValidation();
  },
  methods: {
    triggerValidation() {
      this.$emit('form-validation', !this.$v.$invalid);
    },
    mappingError(field) {
      if (!field.isRequired) {
        return 'Requires Value';
      }
      if (!field.numeric) {
        return 'Requires numeric value';
      }
      if (!field.minValue) {
        return 'Min value is 1';
      }
      if (!field.maxLength) {
        return 'Only allowed set up to 3 numbers';
      }
      return '';
    },
  },
};
</script>
