<template>
  <b-table :tbody-tr-class="success ? 'table-success' : 'table-danger'" :items="mapResultToTable(items)" :fields="fields">
    <template #cell(operator)="data">
      {{ beautifyOperator(data.value) }}
    </template>
    <template #cell(value)="data">
      {{ data.value }}%
    </template>
    <template #cell(expected)="data">
      {{ data.value }}%
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'ChecksTable',
  props: {
    configs: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Object,
      default: () => {},
    },
    fields: {
      type: Array,
      default: () => [],
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    mapResultToTable(checks) {
      const rows = [];
      Object.entries(checks).forEach(([ key, value ]) => {
        rows.push({
          field: key,
          value: value.percentage_valid,
          absolute: value.valid,
          expected: this.configs.find(c => c.field === key).value,
          operator: this.configs.find(c => c.field === key).operator,
        });
      });
      return rows;
    },
    beautifyOperator(operator) {
      switch (operator) {
        case '>':
          return 'greater than';
        case '>=':
          return 'greater than or equal to';
        case '<':
          return 'less than';
        case '<=':
          return 'less than or equal to';
        case '=':
          return 'equal to';
        case '!=':
          return 'not equal to';
        default:
          return operator;
      }
    },
  },
};
</script>
